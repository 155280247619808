.container {
    position: relative;
    padding: var(--spacing-core-20) 0;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-core-6);
}

.errorCode {
    font-size: 256px;
    line-height: 1;
    color: var(--color-background-tertiary-default);
    font-weight: bolder;
}

.buttonGroup {
    width: 32rem;
}

@media (width <= 600px) {
    .container {
        padding: var(--spacing-core-10) 1rem;
    }

    .errorCode {
        font-size: 128px;
    }

    .buttonGroup {
        width: 90%;
    }
}